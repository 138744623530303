(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).ready(function () {
  console.log('...');

  //announcements
  var swiper = new Swiper('.announcements .swiper-container', {
    direction: 'vertical',
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    speed: 500
  });

  //product-group
  var swiper = new Swiper('.main-product-group .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 28,
    speed: 500,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 1
      },
      1150: {
        slidesPerView: 2
      }
    }
  });

  //representations
  var swiper = new Swiper('.main-representations .swiper-container', {
    slidesPerView: 5,
    spaceBetween: 20,
    speed: 500,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      550: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 2
      },
      991: {
        slidesPerView: 3
      },
      1150: {
        slidesPerView: 4
      }
    }
  });
  $('.mobile-menu-title').click(function () {
    $('.main-panel-group').toggleClass('active');
  });
  var _cookiePolicy = Cookies.get("cookiePolicy");
  if ($(".cookie-policy").length > 0 && _cookiePolicy != "true") {
    $('.cookie-policy').addClass('active');
  }
  $('.cookie-policy .cookie-close').click(function (e) {
    e.preventDefault();
    $(this).parents('.cookie-policy').removeClass('active');
    Cookies.set("cookiePolicy", "true", {
      expires: 999
    });
  });
  var _popup = Cookies.get("popup");
  var lang = $('html').attr('lang');
  if (_popup != "true") {
    if (lang == 'tr') {
      $.fancybox.open({
        src: 'assets/images/content/new-year-tr.jpeg',
        afterClose: function afterClose() {
          Cookies.set("popup", "true", {
            expires: 999
          });
        }
      });
    } else {
      $.fancybox.open({
        src: 'assets/images/content/new-year-en.jpeg',
        afterClose: function afterClose() {
          Cookies.set("popup", "true", {
            expires: 999
          });
        }
      });
    }
  }

  //form
  $.validator.addMethod("validate_email", function (value, element) {
    if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }, "Lütfen geçerli bir e-posta adresi giriniz.");
  $.validator.addMethod("lettersonly", function (value, element) {
    return this.optional(element) || /^[a-zA-Z ıİşŞÜüğĞÇçöÖ]+$/i.test(value);
  });

  //contactForm
  $("#contactForm").validate({
    rules: {
      name: {
        required: true,
        lettersonly: true
      },
      email: {
        required: true,
        validate_email: true
      },
      phone: {
        required: true,
        number: true
      },
      message: {
        required: true
      }
    },
    messages: {
      name: {
        required: "Zorunlu alan.",
        lettersonly: "Lütfen doğru şekilde girin."
      },
      email: {
        required: "Zorunlu alan.",
        validate_email: "Lütfen geçeerli bir e-posta adresi girin."
      },
      phone: {
        required: "Zorunlu alan.",
        number: "Lütfen sadece sayı girin."
      },
      message: {
        required: "Zorunlu alan."
      }
    },
    submitHandler: function submitHandler(form) {
      // do other things for a valid form
      //console.log('form submit');
      formGonder(form);
    }
  });
  $(".announcements .panel-group .panel-heading a").click(function () {
    $(this).parents(".panel-group").find(".panel-default").each(function (index, el) {
      $(this).removeClass('active');
    });
    $(this).parents(".panel-default").addClass('active');
    var aa = $(this).parents('.panel-default').find('.panel-collapse');
    if (aa.hasClass('in')) {
      $(this).parents('.panel-default').removeClass('active');
    }
  });
});
function formGonder(form) {
  var action = $(form).attr("action"),
    method = $(form).attr("method"),
    name = $(form).attr('name'),
    veri = $(form).serialize(),
    validate = $(form);

  // console.log(veri);
  $.ajax({
    type: method,
    url: action,
    data: veri,
    cache: false,
    success: function success(response) {
      // data.success = true;
      if (response == 1) {
        $('#contactForm').find('p').addClass('success');
        $('#contactForm').find('p').html($('#contactForm').find('p').data('success'));
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } else {
        $('#contactForm').find('p').addClass('error');
        $('#contactForm').find('p').html($('#contactForm').find('p').data('error'));
      }
    }
  });
}

},{}]},{},[1]);
